
























































import mixins from 'vue-typed-mixins'
import {
  chapterStore,
  snackbarStore,
} from '@/store'
import ChapterCreateEditFields from '@/components/Chapter/CreateEditFields.vue'
import {
  newChapter,
} from '@/util/chapter'
import {
  form,
} from '@/mixins'

export default mixins(form)
  .extend({
    components: {
      ChapterCreateEditFields,
    },
    data: () => ({
      loadingCreate: false,
      newChapter: newChapter(),
    }),
    methods: {
      async create () {
        if (!this.validateForm()) return
        try {
          this.loadingCreate = true
          await chapterStore.create(this.newChapter)
          this.$router.go(-1)
          snackbarStore.addSiteMessage({
            type: `success`,
            snackMessage: `Chapter successfully created`,
          })
        } catch (err) {
          snackbarStore.addSiteError({
            err,
            snackMessage: `Error updating chapter`,
          })
        }
        this.loadingCreate = false
      },
      async reset () {
        this.newChapter = newChapter()
      },
    },
  })
