import {
  Lesson,
} from '@/types/lesson'

export const newLesson = (): Lesson => ({
  name: ``,
  translation: ``,
  type: `vocabulary`,
  typeDataIds: [
    ``,
  ],
})
