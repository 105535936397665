import {
  Chapter,
} from '@/types/chapter'

export const newChapter = (): Chapter => ({
  name: ``,
  translation: ``,
  lessonIdsMatrix: [
    {
      lessonIds: [
      ],
    },
  ],
})
