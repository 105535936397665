














































































































































import Vue from 'vue'
import {
  PropValidator,
} from 'vue/types/options'
import {
  DataTableHeader,
} from 'vuetify'
import {
  lessonStore,
} from '@/store'
import cloneDeep from 'clone-deep'
import {
  newChapter,
} from '../../util/chapter'
import {
  Chapter,
} from '@/types/chapter'
import {
  Lesson,
} from '@/types/lesson'
import {
  Id,
} from '@/types/base'
import {
  newLesson,
} from '@/util/lesson'

export default Vue.extend({
  props: {
    chapter: {
      default: newChapter(),
      type: Object,
    } as PropValidator<Chapter>,
  },
  data: () => ({
    headers: [
      {
        text: `Lessons`,
        value: `lessonIdsMatrix`,
        sortable: false,
      },
    ] as DataTableHeader[],
    chapter_: newChapter(),
  }),
  computed: {
    lessons (): (Lesson & Id)[] {
      return lessonStore.data
    },
    isLoadingLessonGetAll (): boolean {
      return lessonStore.isLoadingGetAll
    },
  },
  watch: {
    chapter: {
      immediate: true,
      handler (chapter: (Chapter & Id)) {
        this.updateLocalValue()
        const lessonIds = chapter.lessonIdsMatrix.flatMap((lessonIds) => lessonIds.lessonIds)
        this.loadLessonList(lessonIds)
      },
    },
  },
  methods: {
    updateLocalValue () {
      this.chapter_ = cloneDeep(this.chapter)
    },
    async moveLessonIdsMatrixUp (index: number) {
      const {
        lessonIdsMatrix,
      } = this.chapter_
      const lessonIds = lessonIdsMatrix.splice(index, 1)[0]
      lessonIdsMatrix.splice(index - 1, 0, lessonIds)
    },
    async moveLessonIdsMatrixDown (index: number) {
      const {
        lessonIdsMatrix,
      } = this.chapter_
      const lessonIds = lessonIdsMatrix.splice(index, 1)[0]
      lessonIdsMatrix.splice(index + 1, 0, lessonIds)
    },
    getLessonById (id: string): (Lesson & Id) {
      return lessonStore.dataById(id) || {
        id,
        ...newLesson(),
      }
    },
    loadLessonList (ids: string[]) {
      lessonStore.loadList(ids)
    },
    loadAllLessons () {
      lessonStore.getAll()
    },
  },
})
