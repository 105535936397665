var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-text-field',{attrs:{"rules":[
      _vm.$global.rules.required() ],"label":"Name","outlined":""},model:{value:(_vm.chapter_.name),callback:function ($$v) {_vm.$set(_vm.chapter_, "name", $$v)},expression:"chapter_.name"}}),_c('v-text-field',{attrs:{"rules":[
      _vm.$global.rules.required() ],"label":"Translation","outlined":""},model:{value:(_vm.chapter_.translation),callback:function ($$v) {_vm.$set(_vm.chapter_, "translation", $$v)},expression:"chapter_.translation"}}),_c('v-data-table',{attrs:{"items":_vm.chapter_.lessonIdsMatrix,"headers":_vm.headers},scopedSlots:_vm._u([{key:"body",fn:function(ref){
    var items = ref.items;
return [_c('tbody',[_vm._l((items),function(_,i){return _c('tr',{key:("lessonIds-" + i)},[_c('td',[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.chapter_.lessonIdsMatrix.length > 1)?_c('v-row',{staticClass:"flex-column flex-grow-0",attrs:{"no-gutters":""}},[_c('v-btn',{attrs:{"disabled":i === 0,"text":"","color":"grey darken-1","width":"19","height":"19","min-width":"0"},on:{"click":function($event){return _vm.moveLessonIdsMatrixUp(i)}}},[_c('v-icon',[_vm._v("mdi-chevron-up")])],1),_c('v-btn',{attrs:{"disabled":i === _vm.chapter_.lessonIdsMatrix.length - 1,"text":"","color":"grey darken-1","width":"19","height":"19","min-width":"0"},on:{"click":function($event){return _vm.moveLessonIdsMatrixDown(i)}}},[_c('v-icon',[_vm._v("mdi-chevron-down")])],1)],1):_vm._e(),_c('v-combobox',{attrs:{"loading":_vm.isLoadingLessonGetAll,"items":_vm.lessons,"item-value":function (item) { return item.id; },"return-object":false,"rules":[
                  _vm.$global.rules.required() ],"label":"Lesson IDs","single-line":"","dense":"","flat":"","solo":"","hide-details":"","background-color":"grey lighten-4","multiple":""},on:{"focus":_vm.loadAllLessons},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [_c('v-list-item-action',[_c('v-checkbox',{staticStyle:{"pointer-events":"none"},attrs:{"input-value":_vm.chapter_.lessonIdsMatrix[i].lessonIds.includes(item.id)}})],1),_c('v-list-item-content',[(_vm.getLessonById(item.id).name)?_c('v-list-item-title',[_vm._v(" "+_vm._s(_vm.getLessonById(item.id).name)+" ")]):_c('v-list-item-subtitle',[_vm._v(" Loading... ")]),(_vm.getLessonById(item.id).translation)?_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.getLessonById(item.id).translation)+" ")]):_vm._e()],1)]}},{key:"selection",fn:function(ref){
                var item = ref.item;
                var parent = ref.parent;
return [_c('v-chip',{attrs:{"close":"","small":""},on:{"click:close":function($event){return parent.selectItem(item)}}},[(_vm.getLessonById(item).name)?_c('div',[_c('span',[_vm._v(_vm._s(_vm.getLessonById(item).name))]),_vm._v(" - "),_c('span',{staticClass:"grey--text text--darken-1"},[_vm._v(_vm._s(_vm.getLessonById(item).translation))])]):_c('span',{staticClass:"grey--text"},[_vm._v("Loading...")])])]}}],null,true),model:{value:(_vm.chapter_.lessonIdsMatrix[i].lessonIds),callback:function ($$v) {_vm.$set(_vm.chapter_.lessonIdsMatrix[i], "lessonIds", $$v)},expression:"chapter_.lessonIdsMatrix[i].lessonIds"}}),(_vm.chapter_.lessonIdsMatrix.length > 1)?_c('v-btn',{staticClass:"ml-4",attrs:{"icon":""},on:{"click":function($event){return _vm.chapter_.lessonIdsMatrix.splice(i, 1)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()],1)],1)])}),_c('tr',[_c('td',[_c('v-btn',{attrs:{"text":"","block":"","color":"accent"},on:{"click":function($event){return _vm.chapter_.lessonIdsMatrix.push({ lessonIds: [] })}}},[_c('v-icon',[_vm._v("mdi-plus")]),_vm._v(" Add more ")],1)],1)])],2)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }